// Styles need to be declared before styled-components is setup
import 'normalize.css';
import '@reach/dialog/styles.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { Theme } from './components/styles/theme';
import { GlobalStyles } from './components/styles/globalStyles';
import { App } from './App';
import { UserProvider } from './contexts/UserContext';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

const $base = document.getElementsByTagName('base')[0];
const baseUrl = $base.getAttribute('href') ?? '';
const $root = document.getElementById('root');

ReactDOM.render(
  <React.StrictMode>
    <UserProvider>
        <ThemeProvider theme={Theme}>
          <BrowserRouter basename={baseUrl}>
            <>
              <GlobalStyles />
              <App />
            </>
          </BrowserRouter>
        </ThemeProvider>
    </UserProvider>
  </React.StrictMode>,
$root);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
