import { VFC } from 'react';
import styled from 'styled-components';
import { useUser } from '../hooks/useUser';

const ContentHeader = styled.header`
    background: ${props => props.theme.colors.white};
    padding: ${props => props.theme.spacing[7]};
    margin-bottom: ${props => props.theme.spacing[7]};
    display: flex;
    align-items: center;
    /* border-bottom: 1px solid ${props => props.theme.colors.gray[1]}; */
    box-shadow: ${props => props.theme.shadows.content};
`;

const HeaderLabel = styled.span`
    font-size: ${props => props.theme.fontSize[3]};
    font-weight: bold;
`;

const HeaderActions = styled.div`
    margin-left: auto;
    display: flex;
    align-items: center;
`;

const LogoutButton = styled.button`
    border: 0;
    display: inline-block;
    background-color: ${props => props.theme.colors.gray[3]};
    cursor: pointer;
    padding: ${props => props.theme.spacing[4]} ${props => props.theme.spacing[6]};

    &:hover,
    &:focus,
    &:active {
        text-decoration: underline;
    }
`;

const UserStatus = styled.span`
    display: inline-block;
    margin-right: ${props => props.theme.spacing[6]};
    font-weight: 500;

    span {
        display: block;
        font-weight: normal;
        color: ${props => props.theme.colors.gray[6]};
        margin-bottom: ${props => props.theme.spacing[1]};
    }
`;

export const Header: VFC = () => {
    const { user, logoutUser } = useUser();
    const userStatus = user?.hasCustomer ? user.name : 'Ej registrerad kund';

    return (
        <ContentHeader>
            <HeaderLabel>
                Social Connect
            </HeaderLabel>
            <HeaderActions>
                <UserStatus>
                    <span>Inloggad som</span>
                    {userStatus}
                </UserStatus>
                <LogoutButton onClick={() => logoutUser()}>
                    Logga ut
                </LogoutButton>
            </HeaderActions>
        </ContentHeader>
    );
}
