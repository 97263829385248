import { useState, useEffect, FC, VFC, SVGProps } from 'react';
import styled, { StyledComponent } from 'styled-components';
import { a } from 'react-spring';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { ReactComponent as FacebookLogo } from '../static/facebook.svg';
import { ReactComponent as InstagramLogo } from '../static/instagram.svg';
import * as apiClient from '../utils/apiClient';
import { SocialAccountType, SocialAccount } from '../types';
import { CardOverlay } from './CardOverlay';

//styled(a.div)
const SocialAccountCard = styled.div`
    height: 400px;
    width: 100%;
    padding: ${props => props.theme.spacing[7]};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: ${props => props.theme.shadows.light};
    position: relative;

    svg.icon {
        width: 80px;
        margin-bottom: ${props => props.theme.spacing[7]};
    }

    & > span {
        font-size: ${props => props.theme.fontSize[3]};
        font-weight: 500;
        word-break: break-word;
    }
`;

const CardButton = styled.button`
    border: 0;
    background: transparent;
    position: absolute;
    bottom: ${props => props.theme.spacing[7]};
    left: 50%;
    transform: translateX(-50%) scale(1);
    margin: 0;
    padding: 0;
    cursor: pointer;
    transition: transform .25s ease;
    will-change: transform;

    &:hover,
    &:focus,
    &:active {
        transform: translateX(-50%) scale(1.2);
    }
`;

const CardIcon = styled(IoIosCloseCircleOutline)`
    font-size: ${props => props.theme.fontSize[5]};
    color: ${props => props.theme.colors.white};
`;

const FacebookAccountCard = styled(SocialAccountCard)`
    background: ${props => props.theme.colors.brand.facebook};
    color: ${props => props.theme.colors.white};
`;

const InstagramAccountCard = styled(SocialAccountCard)`
    background: ${props => props.theme.colors.brand.instagram};
    color: ${props => props.theme.colors.white};
`;

const RemoveAccountButton = styled.button`
    border: 0;
    margin: 0;
    padding: ${props => props.theme.spacing[4]} ${props => props.theme.spacing[7]};
    background: ${props => props.theme.colors.state.error};
    font-weight: bold;
    color: ${props => props.theme.colors.white};
    box-shadow: ${props => props.theme.shadows.light};
    transform: translateY(0);
    will-change: box-shadow, transform;
    transition: box-shadow .25s ease, transform .25s ease;
    cursor: pointer;

    &:hover:not(:disabled),
    &:focus:not(:disabled),
    &:active:not(:disabled) {
        transform: translateY(-2px);
        box-shadow: ${props => props.theme.shadows.large};
    }

    &:disabled {
        background: ${props => props.theme.colors.gray[5]};
        cursor: not-allowed;
    }
`;

interface AccountProps {
    account: SocialAccount;
    updateAccountList(): void;
}

export const AccountCard: VFC<AccountProps> = ({ account, updateAccountList }) => {
    const [ showOverlay, setShowOverlay ] = useState(false);
    const [ errors, setErrors ] = useState<string[]>([]);

    let CardComponent: StyledComponent<any, any> = SocialAccountCard;
    let IconComponent: FC<SVGProps<SVGSVGElement>> | null = null;

    switch (account.type) {
        case SocialAccountType.FacebookPage:
            IconComponent = FacebookLogo;
            CardComponent = FacebookAccountCard;
            break;
        case SocialAccountType.InstagramAccount:
            IconComponent = InstagramLogo;
            CardComponent = InstagramAccountCard;
            break;
    }

    async function handleRemoveAccount(id?: string): Promise<void> {
        if (!id) return;

        try {
            const res = await apiClient.deleteAccount(id);

            if (res?.success) {
                updateAccountList();
            } else if (res?.feeds != null) {
                setErrors(res.feeds);
            }

        } catch (error) {
            console.log('woopsie');
        }
    }

    useEffect(() => {
        if (showOverlay) {
            setErrors([]);
        }
    }, [ showOverlay ]);

    return (
        <CardComponent>
            {IconComponent && <IconComponent className="icon" />}
            <span>{account.name}</span>
            <CardButton title="Ta bort" onClick={() => setShowOverlay(state => !state)}>
                <CardIcon />
            </CardButton>
            <CardOverlay show={showOverlay} handleDismiss={setShowOverlay}>
                <h4>Ta bort {account.name}?</h4>
                {errors?.length > 0 && (
                    <div className="overlay__error">
                        <span className="overlay__subheader">Kontot används i flöden:</span>
                        {errors.map((item, index) => <span key={index}>{item}</span>)}
                    </div>
                )}
                <RemoveAccountButton disabled={!!(errors?.length)} onClick={() => handleRemoveAccount(account.id)}>Ta bort</RemoveAccountButton>
            </CardOverlay>
        </CardComponent>
    );
};

export const AnimatedAccountCard = a(AccountCard);
