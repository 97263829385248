/**
 * Type declaration file for common types across the project
 */

export enum SocialAccountType {
    FacebookUser = 'facebookuser',
    FacebookPage = 'facebookpage',
    InstagramAccount = 'instagram',
}

export enum AccountType {
    None = '',
    Facebook = 'facebook',
    Instagram = 'instagram',
}

export interface SocialAccount {
    id: string;
    name: string
    type?: SocialAccountType;
}

export interface FeedAccount {
    id: string;
    type: SocialAccountType;
    name: string;
}

export interface Feed {
    id: string;
    name: string;
    url: string;
    accounts: FeedAccount[];
}

export interface User {
    id: string;
    name: string;
    hasCustomer: Boolean;
}

export interface FeedListItem {
    id: string;
    name: string;
}

export interface FeedList {
    feeds: FeedListItem[];
}
