import { useContext } from 'react';
import { UserContext } from '../contexts/UserContext';
import { API_LOGIN_FACEBOOK } from '../constants/apiConstants';
import * as apiClient from '../utils/apiClient';
import { User } from '../types';

interface UseUserState {
    user: User | null;
    setUser(user: User | null): void;
    performLogin(from?: string | null): void;
    logoutUser(): Promise<void>;
}

export function useUser(): UseUserState {
    const { user, setUser } = useContext(UserContext);

    function performLogin(from?: string | null) {
        const pathname = from ?? '';

        window.location.href = `${API_LOGIN_FACEBOOK}?returnurl=${pathname}`;
    }

    async function logoutUser(): Promise<void> {
        try {
            await apiClient.logout();

            setUser(null);
        } catch (error) {
            console.log('Något gick åt fan');
        }
    }

    return {
        user,
        setUser,
        performLogin,
        logoutUser,
    };
}
