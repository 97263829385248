import { VFC } from 'react';
import styled from 'styled-components';
import { useTransition } from 'react-spring';
import { Page } from '../components/Page';
import { PageHeading } from '../components/styles/PageHeading';
import { ACCOUNT_PAGE_TITLE } from '../constants/pageConstants';
import { useAxios } from '../hooks/useAxios';
import { API_ACCOUNT_LIST } from '../constants/apiConstants';
import { Spinner } from '../components/Spinner';
import { SocialAccount } from '../types';

import { ConnectAccountCard } from '../components/ConnectAccountCard';
import { AnimatedAccountCard } from '../components/AccountCard';

const AccountWrap = styled.div`
    flex-wrap: wrap;
    display: grid;
    grid-template-columns: 1fr;
    gap: ${props => props.theme.spacing[7]};

    @media (min-width: 950px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media (min-width: 1200px) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    @media (min-width: 1750px) {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
`;

interface GetAccountsResponse {
    accounts: SocialAccount[];
}

export const Accounts: VFC = () => {
    const { data, isLoading, fetchData } = useAxios<GetAccountsResponse>(API_ACCOUNT_LIST);
    const transitions = useTransition(data?.accounts ?? [], {
        keys: x => x.id,
        from: {
            opacity: 0,
            transform: 'translate(0,25%)',
        },
        enter: {
            opacity: 1,
            transform: 'translate(0,0)',
        },
        leave: {
            opacity: 0,
            transform: 'translate(0,25%)',
        },
    });

    return (
        <Page>
            <PageHeading>{ACCOUNT_PAGE_TITLE}</PageHeading>
            <AccountWrap>
                <ConnectAccountCard updateAccountList={fetchData} />
                {isLoading ? (
                    <Spinner size={12} />
                ) : transitions((props, item) => (
                    <AnimatedAccountCard style={props} account={item} key={item.id} updateAccountList={fetchData} />
                ))}
            </AccountWrap>
        </Page>
    );
};
