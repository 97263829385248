import { VFC } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { ReactComponent as AdvaniaLogo } from '../static/advanialogo.svg';
import { Navigation } from './Navigation';

const SidebarWrapper = styled.aside`
    position: sticky;
    top: 0;
    height: 100vh;
    min-width: 260px;
    max-width: 400px;
    flex: 1 1 25%;
    padding: ${props => props.theme.spacing[7]} ${props => props.theme.spacing[7]};
    display: flex;
    flex-direction: column;
`;

const LogoWrapper = styled(Link)`
    display: inline-block;
    margin-bottom: ${props => props.theme.spacing[12]};
    img {
        max-width: 130px;
    }
`;

const Logo = styled(AdvaniaLogo)`
    max-width: 130px;
`;

export const Sidebar: VFC = () => {
    return (
        <SidebarWrapper>
            <LogoWrapper to="/">
                <Logo />
            </LogoWrapper>
            <Navigation />
        </SidebarWrapper>
    );
};
