import { VFC } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { useUser } from '../hooks/useUser';
import { ModalView, ModalIFrame } from './ModalView';
import { FLOW_PAGE_TITLE,START_PAGE_TITLE, ACCOUNT_PAGE_TITLE } from '../constants/pageConstants';

const Nav = styled.nav`
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* padding-bottom: ${props => props.theme.spacing[7]}; */
`;

const NavLinks = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;
`;

const activeClassName = 'active';
const StyledNavLink = styled(NavLink).attrs({
    activeClassName: activeClassName,
})`
    display: inline-block;
    padding: ${props => props.theme.spacing[4]} 0;
    text-decoration: none;
    font-size: ${props => props.theme.fontSize[2]};
    color: ${props => props.theme.colors.black};
    transition: all .25s ease;

    &.${activeClassName} {
        background: ${props => props.theme.colors.app.primary};
        color: ${props => props.theme.colors.white};
        padding: ${props => props.theme.spacing[4]} ${props => props.theme.spacing[6]};
    }
`;

const SecondaryNavWrapper = styled.div``;

export const Navigation: VFC = () => {
    const { user } = useUser();

    return (
        <Nav role="navigation">
            <NavLinks>
                <li>
                    <StyledNavLink exact to="/" title="Startsida">
                        {START_PAGE_TITLE}
                    </StyledNavLink>
                </li>
                {user?.hasCustomer && <>
                    <li>
                        <StyledNavLink to="/accounts" title="Hantera konton för sociala medier">
                            {ACCOUNT_PAGE_TITLE}
                        </StyledNavLink>
                    </li>
                    <li>
                        <StyledNavLink to="/flows" title="Hantera anpassade flöden">
                            {FLOW_PAGE_TITLE}
                        </StyledNavLink>
                    </li>
                </>}
            </NavLinks>
            <SecondaryNavWrapper>
                <ModalView buttonText="Terms of service" label="Terms of service">xyz</ModalView>
                <ModalView buttonText="Privacy policy" label="Privacy policy">
                    <ModalIFrame src="/privacy-policy.html" title="SocialConnects integritetspolicy - dokument" aria-label="SocialConnects integritetspolicy - dokument" />
                </ModalView>
            </SecondaryNavWrapper>
        </Nav>
    );
}
