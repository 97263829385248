import { FC } from 'react';
import styled from 'styled-components';

interface StyledPageProps {
    $full?: boolean;
}

const PageWrap = styled.div<StyledPageProps>`
    padding: ${props => props.$full ? 0 : props.theme.spacing[7]};
    padding-top: 0;
    height: 100%;
`;

const InnerPage = styled.div<StyledPageProps>`
    height: 100%;
    background: ${props => props.theme.colors.white};
    box-shadow: ${props => props.$full ? 'none' : props.theme.shadows.content};
    padding: ${props => props.theme.spacing[7]};
    overflow-y: auto;
    display: flex;
    flex-direction: column;
`;

interface PageProps {
    full?: boolean;
}

export const Page: FC<PageProps> = ({ children, full }) => {
    const isFull = full ?? false;

    return (
        <PageWrap $full={isFull}>
            <InnerPage $full={isFull}>
                {children}
            </InnerPage>
        </PageWrap>
    );
}

