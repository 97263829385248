import { Page } from '../components/Page';
import { PageHeading } from '../components/styles/PageHeading';
import { ContentWrap } from '../components/styles/ContentWrap';
import { START_PAGE_TITLE, ACCOUNT_PAGE_TITLE, FLOW_PAGE_TITLE } from '../constants/pageConstants';
import { useUser } from '../hooks/useUser';

export const Home = () => {
    const { user } = useUser();

    return (
        <Page>
            <PageHeading>{START_PAGE_TITLE}</PageHeading>
            <ContentWrap $column>
                {!user?.hasCustomer ? (
                    <p>
                        Du är inte kopplad till en kund i systemet och kan därför ej lägga till konton eller hantera flöden.
                    </p>
                ) : (<>
                    <p>
                        Du är inloggad som {user.name}.
                        Använd menyn till vänster för att gå till olika vyer.
                    </p>
                    <p>Gå till {ACCOUNT_PAGE_TITLE} för att koppla till ditt kundkonto till dina Sociala medier.</p>
                    <p>Gå till {FLOW_PAGE_TITLE} för att skapa flöden med olika kopplade konton.</p>
                </>)}
            </ContentWrap>
        </Page>
    );
}
