import { VFC } from 'react';
import { FlowHandler } from '../components/FlowHandler';

export interface AddFlowProps {
    updateFeedList(): void;
}

export const AddFlow: VFC<AddFlowProps> = ({updateFeedList}) => {
    return <FlowHandler updateFeedList={updateFeedList} />
};
