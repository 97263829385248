import { useCallback, useEffect, VFC } from 'react';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom';
import { useUser } from '../hooks/useUser';
import { ModalView, ModalIFrame } from '../components/ModalView';
import { ReactComponent as FacebookLogo } from '../static/facebook.svg';
import { ReactComponent as AdvaniaLogo } from '../static/advanialogo.svg';
import socialbackground from '../static/socialbackground.jpg';
import * as apiClient from '../utils/apiClient';

const PageWrap = styled.div`
    height: 100%;
    display: flex;
`;

const Content = styled.div`
    flex: 0 0 700px;
    position: relative;
    padding: ${props => props.theme.spacing[8]};
`;

const InnerContent = styled.div`
    
    h1 {
        font-size: ${props => props.theme.fontSize[6]};
        font-weight: 900;
        color: ${props => props.theme.colors.black};
    }

    p {
        font-size: ${props => props.theme.fontSize[3]};
        line-height: 1.4em;
        color: ${props => props.theme.colors.gray[5]};
        margin-bottom: ${props => props.theme.spacing[12]};
    }
`;

const FBLoginButton = styled.button`
    background-color: ${props => props.theme.colors.brand.facebook};
    color: ${props => props.theme.colors.white};
    font-weight: bold;
    border: 0;
    padding: ${props => props.theme.spacing[4]} ${props => props.theme.spacing[6]};
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: ${props => props.theme.fontSize[3]};
    margin-bottom: ${props => props.theme.spacing[7]};
    transform: translateY(0);
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    will-change: transform, box-shadow;
    transition: transform .25s ease, box-shadow .25s ease;

    &:hover,
    &:focus,
    &:active {
        box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
        transform: translateY(-2px);
    }

    .light {
        font-weight: 100;
    }
`;

const FBButtonIcon = styled(FacebookLogo)`
    width: ${props => props.theme.spacing[8]};
    margin-right: ${props => props.theme.spacing[6]};
`;

const BackgroundImage = styled.div`
    flex: 1 0 auto;
    background: url(${socialbackground});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
`;

const SiteLogo = styled(AdvaniaLogo)`
    margin-bottom: ${props => props.theme.spacing[14]};
    max-width: 200px;
`;

interface LoginProps {
    from?: string | null;
}

export const Login: VFC<LoginProps> = ({ from }) => {
    const { performLogin, user, setUser } = useUser();

    const checkUser = useCallback(async () => {
        try {
            const currentUser = await apiClient.currentUser();

            if (currentUser != null) {
                setUser(currentUser);
            }
        } catch (error) {
            console.log("Fel vid inloggning");
        }
    }, [ setUser ]);

    useEffect(() => {
        checkUser();
    }, [ checkUser ]);

    if (user != null) {
        return (<Redirect to={from || '/'} />);
    }

    return (
        <PageWrap>
            <Content>
                <SiteLogo />
                <InnerContent>
                    <h1>Social Connect</h1>
                    <p>Ta din social-media närvaro till webbplatsen! Skapa anpassade flöden med fortsatt åtkomst vid källans eventuella nedtid.</p>
                    <FBLoginButton onClick={() => performLogin(from)}>
                        <FBButtonIcon />
                        <span>Logga in <span className="light">med</span> Facebook</span>
                    </FBLoginButton>

                    <ModalView buttonText="Terms of service" label="Terms of service">xyz</ModalView>
                    <ModalView buttonText="Privacy policy" label="Privacy policy">
                        <ModalIFrame src="/privacy-policy.html" title="Privacy policy popup" />
                    </ModalView>
                </InnerContent>
            </Content>
            <BackgroundImage />
        </PageWrap>
    );
}
