import { CSSProperties, VFC } from 'react';
import { Route, Link, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { useTransition, a } from 'react-spring';
import { IoIosAddCircleOutline, IoIosArrowRoundForward } from 'react-icons/io';
import { API_FEED_LIST } from '../constants/apiConstants';
import { FLOW_PAGE_TITLE } from '../constants/pageConstants';
import { Page } from '../components/Page';
import { PageHeading } from '../components/styles/PageHeading';
import { ContentWrap } from '../components/styles/ContentWrap';
import { ViewFlow } from './ViewFlow';
import { AddFlow } from './AddFlow';
import { useAxios } from '../hooks/useAxios';
import { Spinner } from '../components/Spinner';
import { FeedList } from '../types';

const FlowList = styled.div`
    flex: 1 0 auto;
    width: 40%;
    min-width: 230px;
    max-width: ${props => props.theme.widths.innerSidebar};
    background: ${props => props.theme.colors.gray[1]};
    padding: ${props => props.theme.spacing[2]};
`;

interface FlowListItemProps {
    $addflow?: boolean;
}

const FlowListItem = styled(Link)<FlowListItemProps>`
    border: 0;
    display: block;
    padding: ${props => props.theme.spacing[6]} ${props => props.theme.spacing[6]};
    width: 100%;
    cursor: pointer;
    position: relative;
    margin-bottom: ${props => props.theme.spacing[2]};
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${props => props.$addflow ? 'transparent' : props.theme.colors.gray[5]};
    color: ${props => props.$addflow ? props.theme.colors.gray[5] : props.theme.colors.white};
    border: ${props => props.$addflow ? '1px dashed ' + props.theme.colors.gray[4] : 0};
    box-shadow: ${props => props.$addflow ? 'none' : props.theme.shadows.light};
    transform: translateY(0);
    will-change: transform, box-shadow;
    transition: transform .25s ease, box-shadow .25s ease;
    text-decoration: none;

    &:hover,
    &:focus,
    &:active {
        transform: ${props => props.$addflow ? 'none' : 'translateY(-2px)'};
        box-shadow: ${props => props.$addflow ? 'none' : props.theme.shadows.large};

        span {
            text-decoration: underline;
        }
    }
`;

const AddIcon = styled(IoIosAddCircleOutline)`
    margin-right: ${props => props.theme.spacing[2]};
    font-size: ${props => props.theme.fontSize[2]};
`;

const ButtonIcon = styled(IoIosArrowRoundForward)`
    position: absolute;
    right: ${props => props.theme.spacing[2]};
    top: 50%;
    transform: translateY(-50%);
    font-size: ${props => props.theme.fontSize[3]};
`;

const SingleFlowWrapper = styled.div`
    flex: 1 0 auto;
    width: 60%;
    overflow-x: hidden;
    position: relative;
`;

const SingleFlowPage = styled(a.div)`
    height: 100%;
    width: 100%;
`;

const FlowEmptyState = styled.div`
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding: 0 ${props => props.theme.spacing[7]};
`;

export const Flows: VFC = () => {
    const match = useRouteMatch();
    const location = useLocation();
    const { data, isLoading, fetchData } = useAxios<FeedList>(API_FEED_LIST);
    const transitions = useTransition(location, {
        keys: loc => loc.pathname,
        from: {
            position: 'absolute' as CSSProperties['position'],
            opacity: 0,
            transform: 'translate3d(-15px,0,0)',
        },
        enter: {
            opacity: 1,
            transform: 'translate3d(0%,0,0)',
        },
        leave: {
            opacity: 0,
            transform: 'translate3d(-15px,0,0)',
        },
    });

    return (
        <Page>
            <PageHeading>{FLOW_PAGE_TITLE}</PageHeading>
            <ContentWrap>
                <FlowList>
                    <FlowListItem $addflow={true} to={`${match.url}/add`}>
                        <AddIcon />
                        <span>Skapa nytt flöde</span>
                    </FlowListItem>
                    {isLoading ? (
                        <Spinner size={10} />
                    ) : data?.feeds?.map(item => (
                        <FlowListItem key={item.id} to={`${match.url}/${item.id}`}>
                            {item.name}
                            <ButtonIcon />
                        </FlowListItem>
                    ))}
                </FlowList>
                <SingleFlowWrapper>
                    {transitions((props, item) =>
                        <SingleFlowPage style={props}>
                            <Switch location={item}>
                                <Route
                                    exact
                                    path={`${match.path}/add`}
                                    render={(props) => (
                                        <AddFlow {...props} updateFeedList={fetchData} />
                                    )}
                                />
                                <Route
                                    path={`${match.path}/:flowId`}
                                    render={(props) => (
                                        <ViewFlow {...props} updateFeedList={fetchData} />
                                    )}
                                />
                                <Route
                                    exact
                                    path={match.path}
                                    render={() => <FlowEmptyState>
                                        {isLoading || !data?.feeds?.length
                                        ? (
                                            <span>Välj ett existerande flöde, eller skapa ett nytt.</span>
                                        ) : (
                                            <span>Du har inga flöden, skapa ett flöde för att komma igång!</span>
                                        )}
                                    </FlowEmptyState>}
                                />
                            </Switch>
                        </SingleFlowPage>
                    )}
                </SingleFlowWrapper>
            </ContentWrap>
        </Page>
    );
}
