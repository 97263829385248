import axios from 'axios';
import { User, SocialAccount } from '../types';
import {
    API_USER_CURRENT,
    API_LOGOUT,
    API_FEED_CREATE,
    API_FEED_UPDATE,
    API_FEED_DELETE,
    API_ACCOUNT_DELETE,
    API_FACEBOOK_PAGE_ADD,
    API_FACEBOOK_PAGE_GET,
    API_INSTAGRAM_ACCOUNT_ADD,
    API_INSTAGRAM_ACCOUNT_GET,
} from '../constants/apiConstants';

interface SuccessResponse {
    success: boolean;
}

interface GetInstagramAccountsResponse {
    accounts: SocialAccount[];
}

interface GetFacebookPagesResponse {
    pages: SocialAccount[];
}

interface CreateFeed {
    name: string;
    accounts: string[];
}

interface CreateFeedResponse {
    id: string;
}

interface UpdateFeed {
    id: string;
    name: string;
    accounts: string[];
}

interface DeleteAccountResponse {
    success: boolean;
    feeds: string[];
}

// Axios instance with default config
const axiosInstance = axios.create({
    withCredentials: true,
});

export async function currentUser(): Promise<User> {
    const res = await axiosInstance.get<User>(API_USER_CURRENT, { withCredentials: true });

    return res?.data;
}

export async function logout(): Promise<boolean> {
    const res = await axiosInstance.post<SuccessResponse>(API_LOGOUT, null);

    return res?.data?.success ?? false;
}

export async function createFeed(data: CreateFeed): Promise<string|null> {
    const res = await axiosInstance.post<CreateFeedResponse>(API_FEED_CREATE, data);

    return res?.data?.id;
}

export async function updateFeed(data: UpdateFeed): Promise<boolean> {
    const res = await axiosInstance.put<SuccessResponse>(API_FEED_UPDATE, data);

    return res?.data?.success ?? false;
}

export async function deleteFeed(feedId: string): Promise<boolean> {
    const res = await axiosInstance.delete<SuccessResponse>(API_FEED_DELETE, {
        data: {
            id: feedId,
        },
    });

    return res?.data?.success ?? false;
}

export async function getInstagramAccounts(): Promise<SocialAccount[]|null> {
    const res = await axiosInstance.get<GetInstagramAccountsResponse>(API_INSTAGRAM_ACCOUNT_GET);

    return res?.data?.accounts;
}

export async function connectInstagramAccount(accountId: string): Promise<boolean> {
    const data = {
        accountId,
    };

    const res = await axiosInstance.post<SuccessResponse>(API_INSTAGRAM_ACCOUNT_ADD, data);

    return res?.data?.success ?? false;
}

export async function getFacebookPages(): Promise<SocialAccount[]|null> {
    const res = await axiosInstance.get<GetFacebookPagesResponse>(API_FACEBOOK_PAGE_GET);

    return res?.data?.pages;
}

export async function connectFacebookPage(pageId: string): Promise<boolean> {
    const data = {
        pageId,
    };

    const res = await axiosInstance.post<SuccessResponse>(API_FACEBOOK_PAGE_ADD, data);

    return res?.data?.success;
}

export async function deleteAccount(accountId: string): Promise<DeleteAccountResponse|null> {
    const res = await axiosInstance.delete(API_ACCOUNT_DELETE, {
        data: {
            id: accountId,
        },
    });

    return res?.data;
}

