import { FC } from 'react';
import styled from 'styled-components';

import { Sidebar } from './Sidebar';
import { Header } from './Header';

const Wrapper = styled.div`
  display: flex;
  height: 100%;
  height: fit-content;
`;

//width: calc(100% - 400px);
const Content = styled.main`
  width: 100%;
  flex: 1 1 auto;
  background: ${props => props.theme.colors.gray[0]};
  border-left: 1px solid ${props => props.theme.colors.gray[1]};
  display: flex;
  flex-direction: column;
`;

const InnerContent = styled.div`
  /* padding: ${props => props.theme.spacing[7]}; */
  flex: 1 0 auto;
`;

export const Layout: FC = ({ children }) => {
    return (
      <Wrapper>
        <Sidebar />
        <Content>
          <Header />
          <InnerContent>
            {children}
          </InnerContent>
        </Content>
      </Wrapper>
    );
}

