import styled from 'styled-components';

interface ContentWrapProps {
    $column?: boolean;
}

export const ContentWrap = styled.div<ContentWrapProps>`
    display: flex;
    flex: 1 0 auto;
    flex-direction: ${props => props.$column ? 'column' : 'row'}
`;
