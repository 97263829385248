
// Waits for promise to end, but with a minimum time to resolve
// Which means it wont return until at least "ms" milliseconds
export async function waitMinimum<T>(promise: Promise<T>, ms: number): Promise<T> {
    const timeout = new Promise((resolve) => {
        setTimeout(resolve, ms);
    });

    // Return result of first promise
    const res = await Promise.all([ promise, timeout ]);

    return res[0];
}
