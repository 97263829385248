import { VFC } from 'react';
import styled from 'styled-components';

interface StyledSpinnerProps {
  $size?: number;
  $light?: boolean;
}

const StyledSpinner = styled.svg<StyledSpinnerProps>`
  display: block;
  align-self: center;
  justify-self: center;
  animation: rotate 2s linear infinite;
  margin: 2rem auto;
  width: ${props => props.theme.spacing[props.$size as number] || props.$size};
  height: ${props => props.theme.spacing[props.$size as number] || props.$size}};

  & circle {
    stroke: ${props => props.$light ? props.theme.colors.white : props.theme.colors.app.primary};
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;

interface SpinnerProps {
  size?: number;
  light?: boolean;
}

export const Spinner: VFC<SpinnerProps> = ({ size, light, ...props }) => (
  <StyledSpinner {...props} $size={size} $light={light} viewBox="0 0 50 50">
    <circle
      cx="25"
      cy="25"
      r="20"
      fill="none"
      strokeWidth="4"
    />
  </StyledSpinner>
);

Spinner.defaultProps = {
  size: 8,
};

