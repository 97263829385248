import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
    html {
        box-sizing: border-box;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: ${props => props.theme.colors.black};
    }

    *,
    *:before,
    *:after {
        box-sizing: inherit;
    }

    html,
    body,
    #root {
        height: 100%;
    }

    [data-reach-dialog-overlay] {
        background: rgba(0,0,0,.33);
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow: auto;
        z-index: 1;
    }

    [data-reach-dialog-content] {
        width: 50vw;
        position: relative;
        padding-top: 40px;
        margin: 10vh auto;
        background: #fff;
        padding: 2rem;
        outline: none;
    }
`;
