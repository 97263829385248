export const API_LOGIN_FACEBOOK = '/auth/facebook';
export const API_LOGOUT = '/auth/logout';

export const API_USER_CURRENT = '/api/customer/current';

export const API_ACCOUNT_LIST = '/api/account/list';
export const API_ACCOUNT_DELETE = '/api/account/delete';

export const API_FEED_LIST = '/api/feed/list';
export const API_FEED_GET = '/api/feed/get';
export const API_FEED_CREATE = '/api/feed/create';
export const API_FEED_UPDATE = '/api/feed/update';
export const API_FEED_DELETE = '/api/feed/delete';

export const API_FACEBOOK_PAGE_GET = '/api/facebook/pages';
export const API_FACEBOOK_PAGE_ADD = '/api/facebook/addpage';

export const API_INSTAGRAM_ACCOUNT_GET = '/api/instagram/accounts';
export const API_INSTAGRAM_ACCOUNT_ADD = '/api/instagram/addaccount';
