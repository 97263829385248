import { useState, FC, VFC } from 'react';
import { Dialog } from '@reach/dialog';
import styled from 'styled-components';
import { Spinner } from './Spinner';

const ModalButton = styled.button`
    background: transparent;
    margin: 0 1rem 0 0;
    padding: 0;
    border: 0;
    cursor: pointer;
    color: ${props => props.theme.colors.gray[5]};

    &:hover,
    &:active,
    &:focus {
        text-decoration: underline;
    }
`;

const CloseButton = styled.button`
    display: block;
    margin: 0 auto;
    padding: ${props => props.theme.spacing[3]} ${props => props.theme.spacing[6]};
    color: ${props => props.theme.colors.app.primary};
    background: transparent;
    border: 1px solid ${props => props.theme.colors.app.primary};
    transition: border-radius .25s ease;

    &:hover,
    &:focus,
    &:active {
        border-radius: 25px;
        cursor: pointer;
    }
`;

const ModalSpinner = styled(Spinner)`
    justify-self: center;
    align-self: center;
`;

interface StyledIFrameProps {
    $hidden?: boolean;
}

const StyledIFrame = styled.iframe<StyledIFrameProps>`
    display: ${props => props.$hidden ? 'none' : 'block'};
    border: none;
    height: 50vh;
    width: 100%;
    padding-bottom: ${props => props.theme.spacing[4]};
`;

const IFrameWrapper = styled.div`
    display: flex;
    height: 50vh;
    width: 100%;
`;

const StyledDialog = styled(Dialog)`
    @media (max-width: 950px) {
        width: 90vw;
    }
`;

interface ModalIFrameProps {
    src: string;
    title: string;
}

export const ModalIFrame: VFC<ModalIFrameProps> = (props) => {
    const [ isLoading, setIsLoading ] = useState(true);

    return (
        <IFrameWrapper>
            <StyledIFrame {...props} $hidden={isLoading} onLoad={() => setIsLoading(false)} />
            {isLoading && <ModalSpinner size={10} />}
        </IFrameWrapper>
    );
};

interface ModalViewProps {
    buttonText: string;
    label: string;
}

export const ModalView: FC<ModalViewProps> = ({ buttonText, label, children }) => {
    const [ showDialog, setShowDialog ] = useState(false);
    const open = () => setShowDialog(true);
    const close = () => setShowDialog(false);

    return (
        <>
            <ModalButton onClick={open}>
                {buttonText}
            </ModalButton>
            <StyledDialog isOpen={showDialog} onDismiss={close} aria-label={label}>
                {children}
                <CloseButton onClick={close}>
                    Stäng
                </CloseButton>
            </StyledDialog>
        </>
    )
}
