import { createContext, useState, FunctionComponent } from 'react';
import { User } from '../types';

interface UserContextState {
    user: User | null;
    setUser(user: User | null): void;
}

const contextDefaultState: UserContextState = {
    user: null,
    setUser: () => {},
};

export const UserContext = createContext<UserContextState>(contextDefaultState);

export const UserProvider: FunctionComponent = ({ children }) => {
    const [ user, setUser ] = useState<User|null>(null);
    const state = { user, setUser };

    return (
        <UserContext.Provider value={state}>
            {children}
        </UserContext.Provider>
    );
}
