import { VFC } from 'react';
import { useParams } from 'react-router';
import { useAxios } from '../hooks/useAxios';
import { API_FEED_GET } from '../constants/apiConstants';
import { FlowHandler } from '../components/FlowHandler';
import { Spinner } from '../components/Spinner';
import { Feed } from '../types';

export interface ViewFlowProps {
    updateFeedList(): void;
}

interface FlowParams {
    flowId: string;
}

export const ViewFlow: VFC<ViewFlowProps> = ({ updateFeedList }) => {
    const { flowId } = useParams<FlowParams>();
    const { data, isLoading } = useAxios<Feed>(`${API_FEED_GET}/${flowId}`);

    return (
        <>
        {isLoading || !data
            ? <Spinner />
            : <FlowHandler selectedFeed={data} heading={"Redigera flöde"} updateFeedList={updateFeedList} />
        }
        </>
    );
}
