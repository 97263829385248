import { DefaultTheme } from 'styled-components';

declare module 'styled-components' {
    interface DefaultTheme {
        fontSize: string[];
        widths: {
            innerSidebar: string;
        };
        colors: {
            white: string;
            black: string;
            app: {
                primary: string;
                secondary: string;
            };
            state: {
                error: string;
            };
            gray: string[];
            brand: {
                facebook: string;
                instagram: string;
            };
        };
        spacing: string[];
        shadows: {
            content: string;
            light: string;
            medium: string;
            large: string;
        };
    }
}

export const Theme: DefaultTheme = {
    fontSize: [
        '0.8rem',
        '1rem',
        '1.25rem',
        '1.563rem',
        '1.953rem',
        '2.441rem',
        '3.052rem',
    ],
    widths: {
        innerSidebar: '360px',
    },
    colors: {
        white: '#fff',
        black: '#353535',
        app: {
          primary: '#2d3561',
          secondary: '',
        },
        state: {
            error: '#E53E3E',
        },
        gray: [
            '#F7FAFC',
            '#EDF2F7',
            '#E2E8F0',
            '#CBD5E0',
            '#A0AEC0',
            '#718096',
            '#4A5568',
            '#2D3748',
            '#1A202C',
        ],
        brand: {
            facebook: '#4267B2',
            instagram: 'linear-gradient(45deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d)',
        },
    },
    spacing: [
        '0',
        '.25rem',
        '.5rem',
        '.75rem',
        '1rem',
        '1.25rem',
        '1.5rem',
        '2rem',
        '2.5rem',
        '3rem',
        '4rem',
        '5rem',
        '6rem',
        '8rem',
        '10rem',
        '12rem',
        '14rem',
        '16rem',
    ],
    shadows: {
        content: '0 6px 20px 0 rgba(0, 0, 0, 0.02)',
        light: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
        medium: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
        large: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
    },
};
