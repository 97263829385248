import { useState, VFC } from 'react';
import styled, { StyledComponent, DefaultTheme } from 'styled-components';
import { IoIosClose, IoMdSad } from 'react-icons/io';
import { Spinner } from './Spinner';
import * as apiClient from '../utils/apiClient';
import { AccountType } from '../types';
import { OverlayClose } from './CardOverlay';
import { waitMinimum } from '../utils/waitMinimum';
import { DEFAULT_MIN_TIMEOUT } from '../constants/pageConstants';
import { SocialAccount } from '../types';

//styled(a.div)
const AccountCard = styled.div`
    height: 400px;
    width: 100%;
    padding: ${props => props.theme.spacing[7]};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: ${props => props.theme.shadows.light};
    position: relative;

    svg.icon {
        width: 80px;
        margin-bottom: ${props => props.theme.spacing[7]};
    }

    & > span {
        font-size: ${props => props.theme.fontSize[3]};
        font-weight: 500;
        word-break: break-word;
    }
`;

interface NewAccountCardProps {
    $list?: boolean;
}

const NewAccountCard = styled(AccountCard)<NewAccountCardProps>`
    background: ${props => props.theme.colors.gray[1]};
    padding: ${props => props.theme.spacing[2]};
    display: block;

    & > div {
        height: 100%;
        width: 100%;
        border: 1px dashed ${props => props.theme.colors.gray[4]};
        display: flex;
        flex-direction: column;
        align-items: ${props => props.$list ? 'flex-start' : 'center'};
        justify-content: ${props => props.$list ? 'flex-start' : 'center'};
        padding: ${props => props.theme.spacing[7]};
        padding-top: ${props => props.$list ? props.theme.spacing[10] : props.theme.spacing[7]};
        position: relative;

        h3 {
            color: ${props => props.theme.colors.gray[5]};
        }

        p {
            text-align: center;
            color: ${props => props.theme.colors.gray[5]};
        }
    }
`;

const AddAccountButton = styled.button`
    border: 0;
    margin: 0 0 ${props => props.theme.spacing[4]};
    padding: ${props => props.theme.spacing[4]} ${props => props.theme.spacing[7]};
    text-align: center;
    font-size: ${props => props.theme.fontSize[1]};
    width: 100%;
    font-weight: 500;
    box-shadow: ${props => props.theme.shadows.light};
    transform: translateY(0);
    transition: box-shadow .25s ease, transform .25s ease;
    will-change: transform, box-shadow;
    cursor: pointer;
    background: ${props => props.theme.colors.gray[5]};
    color: ${props => props.theme.colors.white};

    &:hover,
    &:focus,
    &:active {
        transform: translateY(-2px);
        box-shadow: ${props => props.theme.shadows.large};
    }
`;

const AddFacebookButton = styled(AddAccountButton)`
    background: ${props => props.theme.colors.brand.facebook};
    color: ${props => props.theme.colors.white};
`;

const AddInstagramButton = styled(AddAccountButton)`
    background: ${props => props.theme.colors.brand.instagram};
    color: ${props => props.theme.colors.white};
`;

const NoAccountsIcon = styled(IoMdSad)`
    align-self: center;
    color: ${props => props.theme.colors.gray[5]};
`;

interface AddAccountCardProps {
    updateAccountList(): void;
}

export const ConnectAccountCard: VFC<AddAccountCardProps> = ({ updateAccountList }) => {
    const [ showList, setShowList ] = useState(false);
    const [ data, setData ] = useState<SocialAccount[] | null>(null);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ accountType, setAccountType ] = useState<AccountType>(AccountType.None);

    async function handleListAccounts(type: AccountType): Promise<void> {
        try {
            setShowList(true);
            setAccountType(type);
            setIsLoading(true);

            let promise: Promise<SocialAccount[] | null> = Promise.resolve(null);

            switch (type) {
                case AccountType.Facebook:
                    promise = apiClient.getFacebookPages();
                    break;

                case AccountType.Instagram:
                    promise = apiClient.getInstagramAccounts();
                    break;
            }

            const res = await waitMinimum(promise, DEFAULT_MIN_TIMEOUT);
            if (res) {
                setData(res);
            }
        } catch (error) {
            console.log("Något gick åt fan");
        }

        setIsLoading(false);
    }

    async function handleAddAccount(id: string): Promise<void> {
        if (!id || !accountType) return;

        try {
            setIsLoading(true);
            let success = false;

            switch (accountType) {
                case AccountType.Facebook:
                    success = await apiClient.connectFacebookPage(id);
                    break;

                case AccountType.Instagram:
                    success = await apiClient.connectInstagramAccount(id);
                    break;
            }

            if (success) {
                updateAccountList();
            }
        } catch (ex) {
            console.error("Något gick åt fan", ex);
        }

        setAccountType(AccountType.None);
        setIsLoading(false);
        setShowList(false);
    }

    function renderAddAccounts(accounts: SocialAccount[] | null): JSX.Element | JSX.Element[] | null {
        const capitalize = (str: string) => str[0].toUpperCase() + str.substring(1);

        if (!accounts?.length) {
            return (<>
                <NoAccountsIcon size="5rem" />
                <p>Alla {capitalize(accountType)} konton är redan kopplade, eller så har appen bara tillgång till vissa konton.</p>
            </>);
        }

        // Get different styled button depending on account type
        let AddButtonComponent: StyledComponent<'button', DefaultTheme> | null = null;

        switch (accountType) {
            case AccountType.Facebook:
                AddButtonComponent = AddFacebookButton;
                break;
            case AccountType.Instagram:
                AddButtonComponent = AddInstagramButton;
                break;
        }

        if (AddButtonComponent != null) {
            // TS workaround, to force AddButtonComponent to be non nullable
            const ButtonComponent = AddButtonComponent as StyledComponent<'button', DefaultTheme>;

            return accounts.map(({ id, name }) => (
                <ButtonComponent key={id} onClick= {() => handleAddAccount(id)}>{name}</ButtonComponent>
            ));
        }

        return null;
    }

    return (
        <NewAccountCard $list={showList}>
            {showList ? (
                <div>
                    <OverlayClose $list={showList} onClick={() => setShowList(false)}>
                        <IoIosClose />
                    </OverlayClose>
                    {isLoading ? <Spinner size={10} /> : renderAddAccounts(data)}
                </div>
            ) : (
                <div>
                    <h3>Lägg till</h3>
                    <AddFacebookButton onClick={() => handleListAccounts(AccountType.Facebook)}>
                        <span>Facebook-sida</span>
                    </AddFacebookButton>
                    <AddInstagramButton onClick={() => handleListAccounts(AccountType.Instagram)}>
                        <span>Instagram-konto</span>
                    </AddInstagramButton>
                </div>
            )}
        </NewAccountCard>
    );
};
