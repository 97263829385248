import { useRef, useEffect, useState, VFC, FC, SVGProps } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useTransition, a } from 'react-spring';
import { IoIosAddCircleOutline, IoIosArrowRoundBack } from 'react-icons/io';
import _ from 'lodash';
import { Theme } from './styles/theme';
import { API_ACCOUNT_LIST } from '../constants/apiConstants';
import { useAxios } from '../hooks/useAxios';
import { ReactComponent as FacebookLogo } from '../static/facebook.svg';
import { ReactComponent as InstagramLogo } from '../static/instagram.svg';
import * as apiClient from '../utils/apiClient';
import { Spinner } from './Spinner';
import { Feed, FeedAccount, SocialAccountType } from '../types';


const PageWrapper = styled.div`
    display: flex;
    height: 100%;
`;

const AddFlowWrapper = styled.div`
    height: 100%;
    background: ${props => props.theme.colors.gray[0]};
    width: ${props => props.theme.widths.innerSidebar};
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 10;
`;

const PulloutWrapper = styled(a.div)`
    height: 100%;
    background: ${props => props.theme.colors.gray[0]};
    width: ${props => props.theme.widths.innerSidebar};
    display: flex;
    flex-direction: column;
    border-left: 1px solid ${props => props.theme.colors.gray[2]};
`;

const HeaderSection = styled.div`
    padding: 0 ${props => props.theme.spacing[7]};
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const SectionLabel = styled.h2`
    display: block;
`;

const TopSection = styled.div`
    padding: ${props => props.theme.spacing[7]} ${props => props.theme.spacing[7]};
    background: ${props => props.theme.colors.gray[1]};
`;

const FormLabel = styled.label`
    display: block;
    
    color: ${props => props.theme.colors.gray[6]};
    font-weight: 500;
    font-size: ${props => props.theme.fontSize[0]};
`;

const Input = styled.input`
    width: 100%;
    margin-top: ${props => props.theme.spacing[2]};
    border: 0;
    padding: ${props => props.theme.spacing[4]} ${props => props.theme.spacing[4]};
    box-shadow: ${props => props.theme.shadows.content};
`;

const FlowItems = styled.div`
    padding: ${props => props.theme.spacing[7]} ${props => props.theme.spacing[7]};
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
`;

const FlowItemsList = styled.div`
    flex: 1 0 auto;
    flex-direction: column;
`;

const FlowItemsHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${props => props.theme.spacing[4]};
`;

interface FlowButtonProps {
    primary?: boolean;
    big?: boolean;
    danger?: boolean;
}

const FlowButton = styled.button<FlowButtonProps>`
    display: flex;
    align-items: center;
    background: transparent;
    border: 1px solid ${props => props.primary ? props.theme.colors.app.primary : props.danger ? props.theme.colors.state.error : props.theme.colors.gray[4]};
    padding: ${props => props.big ? props.theme.spacing[3] + ' ' + props.theme.spacing[6] : props.theme.spacing[2] + ' ' + props.theme.spacing[4]};
    font-size: ${props => props.big ? props.theme.fontSize[1] : props.theme.fontSize[0]};
    font-weight: 500;
    color: ${props => props.primary ? props.theme.colors.app.primary : props.danger ? props.theme.colors.state.error : props.theme.colors.gray[6]};

    transition: border-radius .25s ease;
    border-radius: 0;

    &:not(:disabled):hover,
    &:not(:disabled):active,
    &:not(:disabled):focus {
        border-radius: 25px;
        cursor: pointer;
    }

    &:disabled {
        opacity: 0.3;
    }
`;

const FooterSection = styled.div`
    background: ${props => props.theme.colors.gray[1]};
    padding: ${props => props.theme.spacing[4]} ${props => props.theme.spacing[7]};
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

interface AddMediaButtonIconProps {
    $rotate?: boolean;
}

const AddMediaButtonIcon = styled(IoIosAddCircleOutline)<AddMediaButtonIconProps>`
    margin-right: ${props => props.theme.spacing[1]};
    font-size: ${props => props.theme.fontSize[1]};
    transition: transform .25s ease;
    transform: ${props => props.$rotate ? 'rotate(45deg)' : 'rotate(0)'};
`;

const BackLink = styled(Link)`
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    color: ${props => props.theme.colors.gray[6]};

    svg {
        transition: transform .25s ease;
        transform: translate3d(0, 0, 0);
    }

    &:hover,
    &:focus,
    &:active {
        text-decoration: underline;

        svg {
            transform: translate3d(-2px, 0, 0);
        }
    }
`;

const BackLinkIcon = styled(IoIosArrowRoundBack)`
    margin-right: ${props => props.theme.spacing[1]};
    font-size: ${props => props.theme.fontSize[2]};
`;

const FlowMediaItem = styled.div`
    display: inline-flex;
    align-items: center;
    font-weight: 500;
    width: 100%;
    padding: ${props => props.theme.spacing[4]} ${props => props.theme.spacing[6]};
    margin-bottom: ${props => props.theme.spacing[4]};
    position: relative;
    overflow: hidden;

    svg.icon {
        width: 30px;
        margin-right: ${props => props.theme.spacing[6]};
    }

    &:hover > button,
    &:focus > button,
    &:active > button {
        opacity: 1;
    }
`;

const FacebookFlowMediaItem = styled(FlowMediaItem)`
    background: ${props => props.theme.colors.brand.facebook};
    color: ${props => props.theme.colors.white};
`;

const InstagramFlowMediaItem = styled(FlowMediaItem)`
    background: ${props => props.theme.colors.brand.instagram};
    color: ${props => props.theme.colors.white};
`;

const FlowMediaItemOverlay = styled.button`
    position: absolute;
    opacity: 0;
    transition: opacity .25s cubic-bezier(.45,.31,.71,.8);
    border: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: ${props => props.theme.colors.state.error};
    color: ${props => props.theme.colors.white};
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    cursor: pointer;
`;

const FlowMediaEmpty = styled.div`
    text-align: center;
    padding: ${props => props.theme.spacing[3]} ${props => props.theme.spacing[7]};
    color: ${props => props.theme.colors.gray[5]};
    line-height: 1.6;
`;

const AccountList = styled.div`
    padding: ${props => props.theme.spacing[2]};
`;

const AccountButton = styled.button`
    padding: ${props => props.theme.spacing[6]};
    background: ${props => props.theme.colors.gray[2]};
    border: 0;
    width: 100%;
    cursor: pointer;
    box-shadow: ${props => props.theme.shadows.light};
    transform: translateY(0);
    transition: box-shadow .25s ease, transform .25s ease;
    will-change: box-shadow, transform;
    display: inline-flex;
    align-items: center;
    font-weight: 500;
    margin-bottom: ${props => props.theme.spacing[2]};

    svg.icon {
        width: 30px;
        margin-right: ${props => props.theme.spacing[6]};
    }

    &:hover,
    &:active,
    &:focus {
        box-shadow: ${props => props.theme.shadows.large};
        transform: translateY(-2px);
    }
`;

const FacebookAccountButton = styled(AccountButton)`
    background: ${props => props.theme.colors.brand.facebook};
    color: ${props => props.theme.colors.white};
`;

const InstagramAccountButton = styled(AccountButton)`
    background: ${props => props.theme.colors.brand.instagram};
    color: ${props => props.theme.colors.white};
`;

const ConfigSection = styled.div`
    background: ${props => props.theme.colors.gray[1]};
    padding: ${props => props.theme.spacing[4]} ${props => props.theme.spacing[7]};
    border-bottom: 1px solid ${props => props.theme.colors.gray[3]};

    label {
        margin-bottom: ${props => props.theme.spacing[2]};
    }
`;

interface CopyInputProps {
    noMargin?: boolean;
}

const CopyInput = styled.div<CopyInputProps>`
    display: flex;
    margin-bottom: ${props => props.noMargin ? 0 : props.theme.spacing[4]};

    button {
        border: 0;
        background: ${props => props.theme.colors.gray[3]};
        color: ${props => props.theme.colors.gray[5]};
        cursor: pointer;
        font-size: ${props => props.theme.fontSize[0]};
        transition: color .25s ease;

        &:hover,
        &:focus,
        &:active {
            color: ${props => props.theme.colors.black};
        }
    }

    input {
        flex: 1 0 auto;
        border: 0;
        padding: ${props => props.theme.spacing[2]} ${props => props.theme.spacing[2]};
        box-shadow: ${props => props.theme.shadows.content};
    }
`;

interface MediaAccountProps {
    mediaAccount: FeedAccount;
    handleClick(mediaAccount: FeedAccount): void;
}

const MediaAccount: VFC<MediaAccountProps> = ({ mediaAccount, handleClick }) => {
    let ButtonComponent = AccountButton;
    let IconComponent: FC<SVGProps<SVGSVGElement>> | null = null;

    switch (mediaAccount.type) {
        //case SocialAccountType.FacebookUser:
        case SocialAccountType.FacebookPage:
            IconComponent = FacebookLogo;
            ButtonComponent = FacebookAccountButton;
            break;
        case SocialAccountType.InstagramAccount:
            IconComponent = InstagramLogo;
            ButtonComponent = InstagramAccountButton;
            break;
    }

    return (
        <ButtonComponent onClick={() => handleClick(mediaAccount)}>
            {IconComponent && <IconComponent className="icon" />}
            <span>{mediaAccount.name}</span>
        </ButtonComponent>
    );
};

interface FlowMediaProps {
    mediaAccount: FeedAccount;
    handleRemove(mediaAccount: FeedAccount): void;
}

const FlowMedia: VFC<FlowMediaProps> = ({ mediaAccount, handleRemove }) => {
    let BrandFlowMediaItem = FlowMediaItem;
    let IconComponent: FC<SVGProps<SVGSVGElement>> | null = null;

    switch (mediaAccount.type) {
        //case SocialAccountType.FacebookUser:
        case SocialAccountType.FacebookPage:
            IconComponent = FacebookLogo;
            BrandFlowMediaItem = FacebookFlowMediaItem;
            break;
        case SocialAccountType.InstagramAccount:
            IconComponent = InstagramLogo;
            BrandFlowMediaItem = InstagramFlowMediaItem;
            break;
    }

    return (
        <BrandFlowMediaItem>
            {IconComponent && <IconComponent className="icon" />}
            <span>{mediaAccount.name}</span>
            <FlowMediaItemOverlay onClick={() => handleRemove(mediaAccount)}>
                Ta bort media?
            </FlowMediaItemOverlay>
        </BrandFlowMediaItem>
    );
};

export interface FlowHandlerProps {
    selectedFeed?: Feed;
    heading?: string;
    updateFeedList(): void;
}

export const FlowHandler: VFC<FlowHandlerProps> = ({ selectedFeed, heading, updateFeedList }) => {
    const [ showPullout, setShowPullout ] = useState(false);
    const [ flowName, setFlowName ] = useState<string>('');
    const [ flowMedias, setFlowMedias ] = useState<FeedAccount[]>([]);
    const flowNameRef = useRef<HTMLInputElement>(null);
    const widgetCopyRef = useRef<HTMLInputElement>(null);
    const fullCopyRef = useRef<HTMLInputElement>(null);

    const { data, isLoading } = useAxios<Feed>(API_ACCOUNT_LIST);
    const history = useHistory();
    const transitions = useTransition(showPullout, {
        from: {
            opacity: 0,
            transform: 'translate3d(-50%,0,0)',
            borderLeft: `1px solid ${Theme.colors.gray[1]}`
        },
        enter: () => [
            { borderLeft: `1px solid ${Theme.colors.gray[2]}` },
            { opacity: 1, transform: 'translate3d(0%,0,0)' },
        ],
        leave: {
            opacity: 0,
            transform: 'translate3d(-50%,0,0)',
            borderLeft: `1px solid ${Theme.colors.gray[1]}`
        },
    });

    useEffect(() => {
        if (selectedFeed) {
            setFlowName(selectedFeed.name);
            setFlowMedias(selectedFeed.accounts);
        }

        flowNameRef.current?.focus();
    }, [ selectedFeed ]);

    async function handleRemoveFlow(): Promise<void> {
        if (!selectedFeed?.id) {
            return;
        }

        try {
            const res = await apiClient.deleteFeed(selectedFeed.id);

            if (res) {
                updateFeedList();
                history.replace('/flows');
            }
        } catch (error) {
            console.log('Något gick fel vid borttag');
        }
    }

    async function handleUpdateFlow(): Promise<void> {
        try {
            if (!selectedFeed?.id) {
                throw new Error('No selectedFeed in FlowHandler!');
            }

            const res = await apiClient.updateFeed({
                id: selectedFeed.id,
                name: flowName,
                accounts: flowMedias.map(item => item.id),
            })

            if (res) {
                updateFeedList();
                history.push(`/flows/${selectedFeed.id}`);
            }
        } catch (error) {
            console.log('Något gick fel, kunde ej skapa');
        }
    }

    async function handleSaveFlow(): Promise<void> {
        try {
            const feedId = await apiClient.createFeed({
                name: flowName,
                accounts: flowMedias.map(item => item.id),
            });

            if (feedId) {
                updateFeedList();
                history.push(`/flows/${feedId}`);
            }
        } catch (error) {
            console.log('Något gick fel, kunde ej skapa');
        }
    }

    function handleMediaClick(mediaAccount: FeedAccount): void {
        setFlowMedias(state => _.uniqBy([...state, mediaAccount], 'id'));
        setShowPullout(false);
    }

    function handleRemoveClick(mediaAccount: FeedAccount): void {
        const { id } = mediaAccount;

        setFlowMedias(state => state.filter(item => item.id !== id));
    }

    function copyToClipBoard(type: string): void {
        if (type) {
            switch (type) {
                case 'widget':
                    widgetCopyRef.current?.select();
                    break;
                case 'full':
                    fullCopyRef.current?.select();
                    break;
                default:
                    return;
            }

            document.execCommand('copy');
        }
    }

    return (
        <PageWrapper>
            <AddFlowWrapper>
                <HeaderSection>
                    <SectionLabel>
                        {heading || "Skapa nytt flöde"}
                    </SectionLabel>
                    {selectedFeed &&
                        <FlowButton danger onClick={handleRemoveFlow}>
                            Ta bort
                        </FlowButton>
                    }
                </HeaderSection>
                <TopSection>
                    <FormLabel htmlFor="flowname">Namn på flödet</FormLabel>
                    <Input
                        value={flowName}
                        onChange={(e) => setFlowName(e.target.value)}
                        type="text"
                        id="flowname"
                        ref={flowNameRef}
                        placeholder="ex. Startsida domän.se" />
                </TopSection>
                <FlowItems>
                    <FlowItemsHeader>
                        <FormLabel>Valda medier</FormLabel>
                        <FlowButton onClick={() => setShowPullout(state => !state)}>
                            <AddMediaButtonIcon $rotate={showPullout} />
                            {showPullout
                                ? <span>Stäng</span>
                                : <span>Lägg till</span>
                            }
                        </FlowButton>
                    </FlowItemsHeader>
                    <FlowItemsList>
                        {flowMedias.length <= 0
                            ? (<FlowMediaEmpty>Här var det tomt, lägg till media för att komma igång!</FlowMediaEmpty>)
                            : flowMedias.map(item => <FlowMedia key={item.id} mediaAccount={item} handleRemove={handleRemoveClick} />)
                        }
                    </FlowItemsList>
                </FlowItems>
                {selectedFeed && <ConfigSection>
                    <FormLabel>Widget-id</FormLabel>
                    <CopyInput>
                        <button onClick={() => copyToClipBoard('widget')}>Kopiera</button>
                        <input type="text" readOnly value={selectedFeed.id} ref={widgetCopyRef} />
                    </CopyInput>
                    <FormLabel>Full länk</FormLabel>
                    <CopyInput noMargin>
                        <button onClick={() => copyToClipBoard('full')}>Kopiera</button>
                        <input type="text" readOnly value={selectedFeed.url} ref={fullCopyRef} />
                    </CopyInput>
                </ConfigSection>}
                <FooterSection>
                    <BackLink to="/flows">
                        <BackLinkIcon />
                        <span>Avbryt</span>
                    </BackLink>
                    {selectedFeed
                        ? <FlowButton big primary onClick={handleUpdateFlow} disabled={flowName && flowMedias.length > 0 ? false : true}>
                            <AddMediaButtonIcon />
                            <span>Uppdatera</span>
                        </FlowButton>
                        : <FlowButton big primary onClick={handleSaveFlow} disabled={flowName && flowMedias.length > 0 ? false : true}>
                            <AddMediaButtonIcon />
                            <span>Spara</span>
                        </FlowButton>
                    }
                </FooterSection>
            </AddFlowWrapper>

            {transitions((props, show) => show &&
                <PulloutWrapper style={props}>
                    <AccountList>
                        {isLoading ? (
                            <Spinner size={8} />
                        ) : !data?.accounts?.length ? (
                            <FlowMediaEmpty>
                                Du har inte några medier att välja. Börja med att koppla dem under <Link to="/accounts">Hantera konton</Link>
                            </FlowMediaEmpty>
                        ) : data.accounts.map(item => (
                            <MediaAccount key={item.id} handleClick={handleMediaClick} mediaAccount={item} />
                        ))}
                    </AccountList>
                </PulloutWrapper>
            )}
        </PageWrapper>
    );
};
