import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useUser } from './useUser';
import { waitMinimum } from '../utils/waitMinimum';
import { DEFAULT_MIN_TIMEOUT } from '../constants/pageConstants';

export interface UseAxiosState<T> {
    data: T | null;
    isError: boolean;
    isLoading: boolean;
    setData(data: T | null): void;
    fetchData(): Promise<void>;
}

export interface UseAxiosOptions<T> {
    url: string;
    initialData?: T | null;
    transform?(data: T | null): T | null;
}

export function useAxios<T>(url: string): UseAxiosState<T>
export function useAxios<T>(opts: UseAxiosOptions<T>): UseAxiosState<T>
export function useAxios<T>(opts: string | UseAxiosOptions<T>): UseAxiosState<T> {
    if (typeof opts == 'string') {
        opts = { url: opts };
    }

    const { url, transform } = opts;
    const initialData = opts?.initialData ?? null;

    const [ data, setData ] = useState<T | null>(initialData);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ isError, setIsError ] = useState(false);
    const [ isCanceled, setIsCanceled ] = useState(false);
    const { setUser, user } = useUser();

    const fetchData = useCallback(async () => {
        setIsError(false);
        setIsCanceled(false);
        setIsLoading(true);

        try {
            // wait for a minimum of DEFAULT_MIN_TIMEOUT ms to avoid too fast FOUC
            // As we use spinners while loading data
            const req = axios.get<T>(url, { withCredentials: true });
            const res = await waitMinimum(req, DEFAULT_MIN_TIMEOUT);
            let body: T | null = res.data ?? null;

            if (typeof transform == 'function') {
                body = transform(body);
            }

            if (!isCanceled) {
                setData(res.data);
            }
        } catch (ex) {
            if (isCanceled) return;

            if (axios.isAxiosError(ex)) {
                const status = ex.response?.status;

                if (status === 401 || status === 403) {
                    setUser(null);
                    return;
                }
            }

            setIsError(true);
        }

        setIsLoading(false);
    }, [ setIsError, setIsCanceled, setIsLoading, setData ]);

    // Fetch data if url changes
    useEffect(() => {
        // Added null check to user to prevent effect loop
        if (user != null) {
            fetchData();
        }

        return () => {
            setIsCanceled(true);
        };
    }, [ url, fetchData ]);

    // Exposed fetchData to force loading if required
    return {
        data,
        setData,
        isLoading,
        isError,
        fetchData,
    };
}
