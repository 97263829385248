import { VFC } from 'react';
import { Route, Redirect, useLocation, useRouteMatch, Switch } from 'react-router';
import * as qs from 'query-string';
import { useUser } from './hooks/useUser';
import { Layout } from './components/Layout';
import { Home } from './pages/Home';
import { Login } from './pages/Login';
import { Accounts } from './pages/Accounts';
import { Flows } from './pages/Flows';

export const App: VFC = () => {
    const { user } = useUser();
    const location = useLocation();
    const loginMatch = useRouteMatch({
        path: '/login',
        exact: true,
    });

    if (loginMatch) {
        const from = qs.parse(location.search, {
            arrayFormat: 'none',
        })?.from as string | null;

        return (<Login from={from} />);
    } else if (user == null) {
        return (<Redirect to={`/login?from=${encodeURIComponent(location.pathname)}`} />);
    }

    return (
        <Layout>
            <Switch>
                <Route exact path="/" component={Home} />
                <Route path="/accounts" component={Accounts} />
                <Route path="/flows" component={Flows} />
            </Switch>
        </Layout>
    );
};
