import { useEffect, useRef, FC } from 'react';
import styled from 'styled-components';
import { useTransition, a } from 'react-spring';
import { IoIosClose} from 'react-icons/io';

const Overlay = styled(a.div)`
    position: absolute;
    padding: ${props => props.theme.spacing[4]};
    width: 100%;
    height: 100%;
`;

const OverlayInner = styled.div`
    background: ${props => props.theme.colors.white};
    height: 100%;
    box-shadow: ${props => props.theme.shadows.content};
    position: relative;
    display: flex;

    .overlay__content {
        color: ${props => props.theme.colors.black};
        padding: ${props => props.theme.spacing[4]};
        text-align: center;
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .overlay__error {
        margin-bottom: ${props => props.theme.spacing[4]};

        span {
            display: block;
            margin-bottom: ${props => props.theme.spacing[2]};
        }

    }

    .overlay__subheader {
        font-weight: bold;
    }
`;

interface OverlayCloseProps {
    $list?: boolean;
}

export const OverlayClose = styled.button<OverlayCloseProps>`
    border: 0;
    background: transparent;
    position: absolute;
    top: ${props => props.theme.spacing[4]};
    right: ${props => props.$list ? props.theme.spacing[5] : props.theme.spacing[4]};
    font-size: ${props => props.theme.fontSize[4]};
    color: ${props => props.theme.colors.black};
    margin: 0;
    padding: 0;
    cursor: pointer;
`;

interface CardOverlayProps {
    show?: boolean;
    handleDismiss(value: boolean): void;
}

export const CardOverlay: FC<CardOverlayProps> = ({ show, handleDismiss, children }) => {
    const closeRef = useRef<HTMLButtonElement>(null);
    const transitions = useTransition(show, {
        from: {
            opacity: 0,
            transform: 'translate3d(0,-10px,0)',
        },
        enter: {
            opacity: 1,
            transform: 'translate3d(0,0,0)',
        },
        leave: {
            opacity: 0,
            transform: 'translate3d(0,-10px,0)',
        },
    });

    useEffect(() => {
        if (show) {
            closeRef.current?.focus();
        }
    }, [ show ]);

    return transitions((props, show) => show &&
        <Overlay style={props}>
            <OverlayInner>
                <OverlayClose onClick={() => handleDismiss(false)} ref={closeRef}>
                    <IoIosClose />
                </OverlayClose>
                <div className="overlay__content">
                    {children}
                </div>
            </OverlayInner>
        </Overlay>
    );
};
